import { createIcon } from '@chakra-ui/icon';

export const Logo = createIcon({
  displayName: 'AllMySerialsLogo',
  viewBox: '0 0 60 60',
  path: (
    <>
      <path
        fill="currentColor"
        d="M6,48.5v-17c0-0.553-0.448-1-1-1s-1,0.447-1,1v17c0,0.553,0.448,1,1,1S6,49.053,6,48.5z"
      />
      <path
        fill="currentColor"
        d="M56,48.5v-17c0-0.553-0.448-1-1-1s-1,0.447-1,1v17c0,0.553,0.448,1,1,1S56,49.053,56,48.5z"
      />
      <path
        fill="currentColor"
        d="M9,30.5c-0.552,0-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1v-9C10,30.947,9.552,30.5,9,30.5z"
      />
      <path
        fill="currentColor"
        d="M12,30.5c-0.552,0-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1v-9C13,30.947,12.552,30.5,12,30.5z"
      />
      <path
        fill="currentColor"
        d="M18,40.5v-9c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1S18,41.053,18,40.5z"
      />
      <path
        fill="currentColor"
        d="M21,40.5v-9c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1S21,41.053,21,40.5z"
      />
      <path
        fill="currentColor"
        d="M24,40.5v-9c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1S24,41.053,24,40.5z"
      />
      <path
        fill="currentColor"
        d="M30,40.5v-9c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1S30,41.053,30,40.5z"
      />
      <path
        fill="currentColor"
        d="M35,31.5c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1V31.5z"
      />
      <path
        fill="currentColor"
        d="M38,31.5c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1V31.5z"
      />
      <path
        fill="currentColor"
        d="M42,30.5c-0.552,0-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1v-9C43,30.947,42.552,30.5,42,30.5z"
      />
      <path
        fill="currentColor"
        d="M45,30.5c-0.552,0-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1v-9C46,30.947,45.552,30.5,45,30.5z"
      />
      <path
        fill="currentColor"
        d="M51,41.5c0.552,0,1-0.447,1-1v-9c0-0.553-0.448-1-1-1s-1,0.447-1,1v9C50,41.053,50.448,41.5,51,41.5z"
      />
      <path
        fill="currentColor"
        d="M4,11.5v14c0,0.553,0.448,1,1,1s1-0.447,1-1v-14c0-0.553-0.448-1-1-1S4,10.947,4,11.5z"
      />
      <path
        fill="currentColor"
        d="M56,25.5v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14c0,0.553,0.448,1,1,1S56,26.053,56,25.5z"
      />
      <path
        fill="currentColor"
        d="M8,25.5c0,0.553,0.448,1,1,1s1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1V25.5z"
      />
      <path
        fill="currentColor"
        d="M12,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C11,26.053,11.448,26.5,12,26.5z"
      />
      <path
        fill="currentColor"
        d="M17,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C16,26.053,16.448,26.5,17,26.5z"
      />
      <path
        fill="currentColor"
        d="M20,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C19,26.053,19.448,26.5,20,26.5z"
      />
      <path
        fill="currentColor"
        d="M23,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C22,26.053,22.448,26.5,23,26.5z"
      />
      <path
        fill="currentColor"
        d="M29,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C28,26.053,28.448,26.5,29,26.5z"
      />
      <path
        fill="currentColor"
        d="M34,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C33,26.053,33.448,26.5,34,26.5z"
      />
      <path
        fill="currentColor"
        d="M37,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C36,26.053,36.448,26.5,37,26.5z"
      />
      <path
        fill="currentColor"
        d="M42,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C41,26.053,41.448,26.5,42,26.5z"
      />
      <path
        fill="currentColor"
        d="M45,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C44,26.053,44.448,26.5,45,26.5z"
      />
      <path
        fill="currentColor"
        d="M51,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C50,26.053,50.448,26.5,51,26.5z"
      />
      <path
        fill="currentColor"
        d="M9,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C10,44.947,9.552,44.5,9,44.5z"
      />
      <path
        fill="currentColor"
        d="M12,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C13,44.947,12.552,44.5,12,44.5z"
      />
      <path
        fill="currentColor"
        d="M15,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C16,44.947,15.552,44.5,15,44.5z"
      />
      <path
        fill="currentColor"
        d="M18,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C19,44.947,18.552,44.5,18,44.5z"
      />
      <path
        fill="currentColor"
        d="M21,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C22,44.947,21.552,44.5,21,44.5z"
      />
      <path
        fill="currentColor"
        d="M24,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C25,44.947,24.552,44.5,24,44.5z"
      />
      <path
        fill="currentColor"
        d="M27,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C28,44.947,27.552,44.5,27,44.5z"
      />
      <path
        fill="currentColor"
        d="M30,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C31,44.947,30.552,44.5,30,44.5z"
      />
      <path
        fill="currentColor"
        d="M33,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C34,44.947,33.552,44.5,33,44.5z"
      />
      <path
        fill="currentColor"
        d="M36,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C37,44.947,36.552,44.5,36,44.5z"
      />
      <path
        fill="currentColor"
        d="M39,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C40,44.947,39.552,44.5,39,44.5z"
      />
      <path
        fill="currentColor"
        d="M42,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C43,44.947,42.552,44.5,42,44.5z"
      />
      <path
        fill="currentColor"
        d="M45,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C46,44.947,45.552,44.5,45,44.5z"
      />
      <path
        fill="currentColor"
        d="M48,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C49,44.947,48.552,44.5,48,44.5z"
      />
      <path
        fill="currentColor"
        d="M52,45.5c0-0.553-0.448-1-1-1s-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1V45.5z"
      />
      <path
        fill="currentColor"
        d="M8,53.5H2v-5c0-0.553-0.448-1-1-1s-1,0.447-1,1v7h8c0.552,0,1-0.447,1-1S8.552,53.5,8,53.5z"
      />
      <path
        fill="currentColor"
        d="M59,47.5c-0.552,0-1,0.447-1,1v5h-5c-0.552,0-1,0.447-1,1s0.448,1,1,1h7v-7C60,47.947,59.552,47.5,59,47.5z"
      />
      <path
        fill="currentColor"
        d="M52,4.5c-0.552,0-1,0.447-1,1s0.448,1,1,1h6v5c0,0.553,0.448,1,1,1s1-0.447,1-1v-7H52z"
      />
      <path
        fill="currentColor"
        d="M1,12.5c0.552,0,1-0.447,1-1v-5h5c0.552,0,1-0.447,1-1s-0.448-1-1-1H0v7C0,12.053,0.448,12.5,1,12.5z"
      />
      <path
        fill="currentColor"
        d="M59,27.5H1c-0.552,0-1,0.447-1,1s0.448,1,1,1h58c0.552,0,1-0.447,1-1S59.552,27.5,59,27.5z"
      />
    </>
  ),
});

export const Github = createIcon({
  displayName: 'GitHubLogo',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"
    />
  ),
});

export const Plausible = createIcon({
  displayName: 'PlausibleAnalyticsLogo',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M12.1835.0017c-.6378-.0097-1.2884.022-1.7246.0996C8.601.424 7.035 1.2116 5.7384 2.4782 4.406 3.7806 3.582 5.299 3.1818 7.1929l-.1387.6445c-.0118 5.3872-.0233 10.7744-.035 16.1617.2914.0081.591-.0392.8416-.0606 2.348-.2868 4.3442-1.7083 5.4315-3.8651.2749-.5497.472-1.182.6094-1.9707.1135-.6691.1195-.8915.1016-4.3807l-.0176-3.6737.1425-.3574c.1972-.49.7425-1.0352 1.2324-1.2324l.3574-.1426 3.3457-.0058c1.8401 0 3.4545-.025 3.58-.0489.5854-.1135 1.2118-.6027 1.4628-1.1464.0717-.1494.1671-.4415.209-.6387.0657-.3286.0604-.4186-.0352-.789-.2987-1.0993-1.3503-2.6234-2.4257-3.5136C16.6247 1.1638 15.2798.4887 13.828.1482c-.3824-.0866-1.0067-.1368-1.6445-.1465zm8.5369 6.8006c-.0506.1798-.098.3662-.172.5215-.3358.7278-1.0382 1.2776-1.8221 1.4296-3.6737.0566-2.5392.0561-3.6737.0566l-3.248.0059-.2695.1074c-.3135.1262-.827.6397-.9531.9531l-.1074.2676.0175 3.576c.0149 2.8888.007 3.5821-.0605 4.125a8.9918 8.9918 0 0 0 1.5683.1386c4.9662.0001 8.992-4.0258 8.992-8.992a8.9918 8.9918 0 0 0-.2715-2.1893Z"
    />
  ),
});

export const Twitter = createIcon({
  displayName: 'TwitterLogo',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
    />
  ),
});
